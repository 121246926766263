import React from 'react'
import { Layout } from 'antd'
import MenuPage from 'containers/Menu/MenuPage'
import ContentPage from './ContentPage'

const { Content } = Layout
const DefaultPage = () => (
  <div>
    <MenuPage />
    <Content className="default-content">
      <ContentPage />
    </Content>
  </div>
)

export default DefaultPage
