import {
  GET_UPLOAD_LOG,
  GET_UPLOAD_LOG_OK,
  GET_UPLOAD_LOG_ERROR,
  GET_UPLOAD_FILE,
  GET_UPLOAD_FILE_OK,
  GET_UPLOAD_FILE_ERROR
} from 'actions/types'

import { createCSV } from 'helpers/file'

const INITIAL_STATE = {
  loading: false,
  uploadLog: {
    data: [],
    count: 0,
    page: 1,
  }
}

export default (state = INITIAL_STATE, { type, ...payload }) => {
  switch (type) {
    case GET_UPLOAD_LOG:
      return {
        ...state,
        loading: true
      }
    case GET_UPLOAD_LOG_OK:
      const data = payload.data
      return {
        ...state,
        uploadLog: {
          data,
          count: parseInt(payload.headers.get('x-pagination-total'), 10),
          page: payload.params.page
        },
        loading: false
      }
    case GET_UPLOAD_LOG_ERROR:
      return {
        ...state,
        loading: false
      }
    case GET_UPLOAD_FILE:
      return {
        ...state,
        loading: true
      }
    case GET_UPLOAD_FILE_OK:
      createCSV(payload.data, payload.params.fileName)
      return {
        ...state,
        loading: false,
      }
    case GET_UPLOAD_FILE_ERROR:
      return {
        ...state,
        loading: false
      }
    default:
      return {
        ...state
      }
  }
}
