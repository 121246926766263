import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import {
  Form,
  Input,
  Icon,
  Button,
  Select
} from 'antd'
import PropTypes from 'prop-types'

import { API_KEY_PAGE } from 'constants/navigation'

const FormItem = Form.Item
const { Option } = Select

class SetKeyForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault()
    const { form } = this.props
    const { setApiKey } = this.props
    form.validateFields((err, values) => {
      if (!err) {
        setApiKey(values)
        const { history } = this.props
        history.push(API_KEY_PAGE)
      }
    })
  }

  render() {
    const { form } = this.props
    const { getFieldDecorator } = form
    return (
      <span>
        <div className="content wrapper">
          <Form onSubmit={this.handleSubmit}>
            <FormItem>
              {getFieldDecorator('service', {
                rules: [{ required: true, message: 'Please input your service name!' }]
              })(
                <Input
                  prefix={<Icon type="lock" className="icon" />}
                  placeholder="Service"
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('role', {
                rules: [{ required: true, message: 'Please input your role' }]
              })(
                <Select>
                  <Option value="guest">Guest</Option>
                  <Option value="admin">Admin</Option>
                </Select>
              )}
            </FormItem>
            <FormItem>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
              <Link style={{ marginLeft: '15px' }} className="ant-btn ant-btn-danger" to={API_KEY_PAGE}>
                Cancel
              </Link>
            </FormItem>
          </Form>
        </div>
      </span>
    )
  }
}

SetKeyForm.propTypes = {
  history: PropTypes.shape(),
  setApiKey: PropTypes.func.isRequired,
  form: PropTypes.shape()
}

export default withRouter(Form.create()(SetKeyForm))
