import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'

import { Switch, Route, withRouter } from 'react-router-dom'
import { Layout } from 'antd'

import { store, persistor } from './boot/configureStore'
import DefaultPage from './components/pages/DefaultPage'
import LoginPage from './containers/Login/LoginPage'

import './style/App.css'

const { Footer } = Layout

const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Layout className="layout">
        <Switch>
          <Route exact path="/login" component={() => <LoginPage />} />
          <Route component={() => <DefaultPage />} />
        </Switch>
        <Footer className="layout-footer">© 2018 Zalora</Footer>
      </Layout>
    </PersistGate>
  </Provider>
)

export default withRouter(App)
