import React from 'react'
import PropTypes from 'prop-types'

import { Table } from 'antd'

const OOSLogTable = ({ outOfStockList, loading }) => {
  const columns = [
    {
      title: 'Country',
      dataIndex: 'country',
      align: 'center'
    },
    {
      title: 'Type',
      dataIndex: 'is_missing',
      align: 'center'
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      align: 'center',
    },
    {
      title: 'BOB SOI ID',
      dataIndex: 'bob_id_sales_order_item',
      align: 'center'
    },
    {
      title: 'OMS SOI ID',
      dataIndex: 'id_sales_order_item',
      align: 'center'
    },
    {
      title: 'Order Number',
      dataIndex: 'order_nr',
      align: 'center'
    },
    {
      title: 'OOS At',
      dataIndex: 'oos_at',
      align: 'center'
    }
  ]

  return (
    <Table
      bordered={true}
      loading={loading}
      pagination={false}
      columns={columns}
      dataSource={outOfStockList}
      rowKey="id_sales_order_item"
      size="small"
    />
  )
}

OOSLogTable.propTypes = {
  outOfStockList: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool.isRequired
}

export default OOSLogTable
