import { connect } from 'react-redux'

import SetKeyForm from 'components/pages/ApiKey/SetKeyForm'

import * as keyActions from 'actions/key'

const mapStateToProps = ({
  key: {
    loading,
    newApiKey
  }
}) => ({ loading, newApiKey })
const mapDispatchToProps = { ...keyActions }

export default connect(mapStateToProps, mapDispatchToProps)(SetKeyForm)
