import { connect } from 'react-redux'

import { getOutOfStockList } from 'actions/stock'

import HomePage from 'components/pages/OOS/OOSPage'

const mapStateToProps = ({
  stock: {
    loading,
    outOfStockList,
  }
}) => {
  outOfStockList.data.forEach((e) => {
    e.is_missing = e.is_missing ? 'WH OOS' : 'System OOS'
  })

  return {
    loading,
    outOfStockList
  }
}

const mapDispatchToProps = {
  getOutOfStockList
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
