import { connect } from 'react-redux'

import ApiKeyPage from 'components/pages/ApiKey/ApiKeyPage'

import * as keyActions from 'actions/key'

const mapStateToProps = ({
  key: {
    loading,
    apiKeys
  }
}) => {
  const keys = apiKeys ? Object.keys(apiKeys).map(key => ({
    'apiKey': key,
    'role': apiKeys[key]
  })) : undefined

  return {
    loading,
    apiKeys: keys
  }
}
const mapDispatchToProps = { ...keyActions }

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeyPage)
