import React from 'react'

const ErrorPage = () => (
  <div className="login-wrapper">
    <div className="wrapper">
      <div className="notfound">
        <div className="notfound-404">
          <h3>Oops! Something went wrong</h3>
          <h1>
            404
          </h1>
        </div>
        <h2>We are sorry, but the page you requested was not found</h2>
      </div>
    </div>
  </div>
)

export default ErrorPage
