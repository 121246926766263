import React from 'react'
import PropTypes from 'prop-types'
import { Upload, message, Icon } from 'antd'
import { UPLOAD_URL } from 'Constants'
import { createToken } from 'helpers/sso'

const Dragger = ({ onUploadComplete }) => {
  const UploadDragger = Upload.Dragger
  const props = {
    name: 'file',
    multiple: false,
    action: UPLOAD_URL,
    accept: '.csv',
    headers: {
      Authorization: createToken()
    },
    onChange(info) {
      const status = info.file.status
      if (status !== 'uploading') {
      }

      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`)
        onUploadComplete()
      } else if (status === 'error') {
        if (info.file.response && info.file.response !== undefined) {
          onUploadComplete()
          message.error(`${info.file.name} file upload failed. Message: ${info.file.response.Message}`)
        } else {
          message.error(`${info.file.name} file upload failed.`)
        }
      }
    }
  }

  return (
    <UploadDragger {...props}>
      <p className="ant-upload-drag-icon">
        <Icon type="inbox" />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
    </UploadDragger>
  )
}

Dragger.propTypes = {
  onUploadComplete: PropTypes.func.isRequired
}

export default Dragger
