import {GET_UPLOAD_LOG, GET_UPLOAD_FILE, SEND_MIGRATION_REQUEST} from './types'
import {SUPPLIER_MIGRATION_URL} from "../Constants";

export const getUploadLog = ({ page, limit }) => (
  {
    type: GET_UPLOAD_LOG,
    params: { page, limit },
    request: {
      url: '/upload_log',
      method: 'GET'
    }
  }
)

export const getUploadFile = ({ id, fileName }) => (
  {
    type: GET_UPLOAD_FILE,
    params: { id, fileName },
    request: {
      url: '/upload_file',
      method: 'GET',
      type: 'csv'
    }
  }
)

export const sendSupplierMigrationRequest = ({sku_list, file, new_supplier, old_supplier, countries, venture, should_copy_stock, should_force_migration}) => (
    {
        type: SEND_MIGRATION_REQUEST,
        params: {sku_list, file, new_supplier, old_supplier, countries, venture, should_copy_stock, should_force_migration},
        request: {
            url: '/migrate-supplier',
            method: 'POST'
        }
    }
)