import React from 'react'
import { Menu, Avatar } from 'antd'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import routes from 'routing/routes'

const SubMenu = Menu.SubMenu

const HeaderNavigator = ({
  menuDefault,
  picture,
  name,
  logOut,
  roleName
}) => {
  const Items = routes.map(route => (
    route.allowedRoles.includes(roleName) ? (
      <Menu.Item key={route.state}>
        <Link to={route.path}>{route.caption}</Link>
      </Menu.Item>
    ) : ''
  ))
  return (
    <Menu
      theme="dark"
      mode="horizontal"
      defaultSelectedKeys={[menuDefault]}
      className="menu"
    >
      {Items}
      <SubMenu
        className="user-avatar sub-menu"
        title={(
          <span>
            <Avatar src={picture} />
          </span>
          )}
      >
        <Menu.Item>{name}</Menu.Item>
        <Menu.Item onClick={logOut}>Log out</Menu.Item>
      </SubMenu>
    </Menu>
  )
}

HeaderNavigator.propTypes = {
  menuDefault: PropTypes.string,
  name: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
  logOut: PropTypes.func.isRequired,
  roleName: PropTypes.string.isRequired
}

export default HeaderNavigator
