import React from 'react'
import { Menu } from 'antd'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import routes from 'routing/routes'


const DrawerNavigator = ({
  toggleDrawer,
  menuDefault,
  logOut,
  roleName
}) => {
  const Items = routes.map(route => (
    route.allowedRoles.includes(roleName) ? (
      <Menu.Item key={route.state}>
        <Link onClick={toggleDrawer} to={route.path}>{route.caption}</Link>
      </Menu.Item>
    ) : ''
  ))
  return (
    <Menu
      theme="dark"
      defaultSelectedKeys={[menuDefault]}
      className="menu"
    >
      {Items}
      <Menu.Item onClick={logOut}>Log out</Menu.Item>
    </Menu>
  )
}

DrawerNavigator.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  menuDefault: PropTypes.string,
  logOut: PropTypes.func.isRequired,
  roleName: PropTypes.string.isRequired
}

export default DrawerNavigator
