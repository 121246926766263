import {
  GET_SKU_DATA, GET_SKU_DATA_OK, GET_SKU_DATA_ERROR
} from 'actions/types'

const INITIAL_STATE = {
  loading: false,
  skuData: []
}

export default (state = INITIAL_STATE, { type, ...payload }) => {
  switch (type) {
    case GET_SKU_DATA:
      return {
        ...state,
        loading: true
      }
    case GET_SKU_DATA_OK:
      const skuData = payload.data
      return {
        ...state,
        skuData,
        loading: false
      }
    case GET_SKU_DATA_ERROR:
      return {
        ...state,
        loading: false
      }
    default:
      return {
        ...state
      }
  }
}
