export const GET_SKU_DATA = 'GET_SKU_DATA'
export const GET_SKU_DATA_OK = 'GET_SKU_DATA_OK'
export const GET_SKU_DATA_ERROR = 'GET_SKU_DATA_ERROR'

// Auth
export const FORM_LOGIN = 'FORM_LOGIN'
export const SSO_LOGIN = 'SSO_LOGIN'
export const SSO_LOGIN_OK = 'SSO_LOGIN_OK'
export const SSO_LOGIN_ERROR = 'SSO_LOGIN_ERROR'

export const LOGOUT = 'LOGOUT'

// Navigation
export const CHANGE_ROUTE = 'CHANGE_ROUTE'

// Upload
export const GET_UPLOAD_LOG = 'GET_UPLOAD_LOG'
export const GET_UPLOAD_LOG_OK = 'GET_UPLOAD_LOG_OK'
export const GET_UPLOAD_LOG_ERROR = 'GET_UPLOAD_LOG_ERROR'

export const GET_UPLOAD_FILE = 'GET_UPLOAD_FILE'
export const GET_UPLOAD_FILE_OK = 'GET_UPLOAD_FILE_OK'
export const GET_UPLOAD_FILE_ERROR = 'GET_UPLOAD_FILE_ERROR'

// Stock
export const GET_OUT_OF_STOCK_STATISTICS = 'GET_OUT_OF_STOCK_STATISTICS'
export const GET_OUT_OF_STOCK_STATISTICS_OK = 'GET_OUT_OF_STOCK_STATISTICS_OK'
export const GET_OUT_OF_STOCK_STATISTICS_ERROR = 'GET_OUT_OF_STOCK_STATISTICS_ERROR'

export const GET_OUT_OF_STOCK_LIST = 'GET_OUT_OF_STOCK_LIST'
export const GET_OUT_OF_STOCK_LIST_OK = 'GET_OUT_OF_STOCK_LIST_OK'
export const GET_OUT_OF_STOCK_LIST_ERROR = 'GET_OUT_OF_STOCK_LIST_ERROR'

// Api key
export const GET_ALL_API_KEY = 'GET_ALL_API_KEY'
export const GET_ALL_API_KEY_OK = 'GET_ALL_API_KEY_OK'
export const DELETE_API_KEY = 'DELETE_API_KEY'
export const DELETE_API_KEY_OK = 'DELETE_API_KEY_OK'
export const SET_API_KEY = 'SET_API_KEY'
export const SET_API_KEY_OK = 'SET_API_KEY_OK'

export const SEND_MIGRATION_REQUEST = 'SEND_MIGRATION_REQUEST'
export const SEND_MIGRATION_REQUEST_OK = 'SEND_MIGRATION_REQUEST_OK'
export const SEND_MIGRATION_REQUEST_ERROR = 'SEND_MIGRATION_REQUEST_ERROR'
