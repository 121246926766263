import React from 'react'
import { Drawer } from 'antd-mobile'
import PropTypes from 'prop-types'
import DrawerNavigator from 'components/pages/Navigator/DrawerNavigator'

const SideBar = ({
  logOut,
  open,
  toggleDrawer,
  menuDefault,
  roleName
}) => {
  const sidebar = (
    <DrawerNavigator
      toggleDrawer={toggleDrawer}
      menuDefault={menuDefault}
      logOut={logOut}
      roleName={roleName}
    />
  )
  return (
    <Drawer
      className="my-drawer"
      style={{ minHeight: document.documentElement }}
      enableDragHandle
      sidebar={sidebar}
      open={open}
      onOpenChange={toggleDrawer}
    >
      <div />
    </Drawer>
  )
}

SideBar.propTypes = {
  menuDefault: PropTypes.string,
  logOut: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  roleName: PropTypes.string.isRequired
}

export default SideBar
