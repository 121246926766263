import { connect } from 'react-redux'

import UploadPage from 'components/pages/SupplierMigration/UploadPage'

import {sendSupplierMigrationRequest} from 'actions/upload'

const mapStateToProps = (state) => {
  const {loading, error, errorMessage} = state.migration
  return {loading, error, errorMessage}
}
const mapDispatchToProps = { sendSupplierMigrationRequest }

export default connect(mapStateToProps, mapDispatchToProps)(UploadPage)
