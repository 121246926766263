import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Table, Icon } from 'antd'
import UploadErrors from './UploadErrors'

class UploadLogTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: [],
      visible: false
    }
  }

  showModal = (errors) => {
    this.setState({
      errors,
      visible: true
    })
  };

  handleOk = (e) => {
    this.setState({
      visible: false
    })
  };

  handleCancel = (e) => {
    this.setState({
      visible: false
    })
  }

  render() {
    const { uploadLog, getUploadFile, loading } = this.props
    const columns = [
      {
        title: 'ID',
        dataIndex: 'IdUploadLog',
        align: 'center'
      },
      {
        title: 'File',
        align: 'center',
        key: 'action',
        render: row => (
          <span>
            <button
              type="button"
              className="link-button"
              onClick={() => { getUploadFile({
                id: row.IdUploadLog,
                fileName: row.FileName
              }) }}
            >
              {row.FileName}
            </button>
          </span>
        ),
      },
      {
        title: 'Uploaded By',
        dataIndex: 'Email',
        align: 'center'
      },
      {
        title: 'Uploaded At',
        dataIndex: 'UpdatedAt',
        align: 'center'
      },
      {
        title: 'Status',
        align: 'center',
        render: row => (
          row.Errors
            ? (
              // eslint-disable-next-line
              <span onClick={() => { this.showModal(row.Errors.split(",")) }}>
                <Icon type="close-circle" className="icon-red" />
                <span style={{ marginLeft: '5px', cursor: 'pointer' }}>Upload fail</span>
              </span>
            )
            : (
              <span>
                <Icon type="check-circle" className="icon-green" />
                <span style={{ marginLeft: '5px' }}>{row.FkUploadStatus}</span>
              </span>
            )
        ),
      }
    ]

    const { errors, visible } = this.state

    return (
      <div>
        <Table
          bordered={true}
          loading={loading}
          pagination={false}
          columns={columns}
          dataSource={uploadLog}
          size="small"
        />
        <UploadErrors
          title="Errors"
          visible={visible}
          errors={errors}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
        />
      </div>
    )
  }
}

UploadLogTable.propTypes = {
  getUploadFile: PropTypes.func.isRequired,
  uploadLog: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool.isRequired
}

export default UploadLogTable
