import React from 'react'

import { Modal } from 'antd'
import PropTypes from 'prop-types'

const UploadErrors = ({
  title,
  visible,
  errors,
  handleOk,
  handleCancel
}) => (
  <Modal
    title={title}
    visible={visible}
    onOk={handleOk}
    onCancel={handleCancel}
  >
    {
      errors.map(error => (
        <div key={error}>{error}</div>
      ))
    }
  </Modal>
)

UploadErrors.propTypes = {
  title: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired
}

export default UploadErrors
