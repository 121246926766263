import { combineReducers } from 'redux'

import sku from './sku'
import user from './user'
import upload from './upload'
import stock from './stock'
import key from './key'
import migration from './supplier_migration'

export default combineReducers({
  sku,
  user,
  upload,
  stock,
  key,
  migration
})
