import React, { Component } from 'react'
import PropTypes from 'prop-types'

class UploadPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      new_supplier: "",
      old_supplier: "",
      countries: [],
      venture: '',
      should_copy_stock: false,
      should_force_migration : false,
      sku_list: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNewSupplierChange = this.handleNewSupplierChange.bind(this);
    this.handleOldSupplierChange = this.handleOldSupplierChange.bind(this);
    this.handleCountriesChange = this.handleCountriesChange.bind(this);
    this.handleVentureChange = this.handleVentureChange.bind(this);
    this.handleShouldCopyStockChange = this.handleShouldCopyStockChange.bind(this);
    this.handleShouldForceMigrationChange = this.handleShouldForceMigrationChange.bind(this);
    this.handleSkuListChange = this.handleSkuListChange.bind(this);
    this.renderLoading = this.renderLoading.bind(this)
    this.renderError = this.renderError.bind(this)
  }

  handleChange(field, event) {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({[field]: value});
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.sendSupplierMigrationRequest(this.state)
  }

  handleCountriesChange(event) {
    this.setState({countries: event.target.value.split(",")})
  }

  handleSkuListChange(event) {
      this.setState({sku_list: event.target.value.split(",")})
  }

  handleOldSupplierChange(event) {
    this.handleChange('old_supplier', event)
  }

  handleNewSupplierChange(event) {
    this.handleChange('new_supplier', event)
  }

  handleVentureChange(event) {
    this.handleChange('venture', event)
  }

  handleShouldCopyStockChange(event) {
    this.setState({should_copy_stock: !!event.target.value});
  }

  handleShouldForceMigrationChange(event) {
    this.setState({should_force_migration: !!event.target.value});
  }

  renderLoading() {
    if (this.props.loading) {
      return (
          <div>
          <div>Loading</div>
              <br/>
          </div>
      )
    }
  }

  renderError() {
    if(this.props.error) {
      return (
          <div>
              <div>
            <span style={{fontWeight: "bold"}}>Error: </span>
            <span style={{color:"red"}}>{this.props.errorMessage}</span>
              </div>
              <br/>
          </div>
      )
    }
  }

  render() {
    return (
      <span>
        <div className="content wrapper">
          {this.renderLoading()}
          {this.renderError()}
          <form onSubmit={this.handleSubmit}>
            <div>
            <label>
          SKUs:
          <input type="file" value={this.state.file} onChange={(event) => {this.setState({file: event.target.files[0]})}} />
        </label>
              </div>
              <br/>
              <div>
                  <label>SKU List: </label>
                  <textarea value={this.state.sku_list.join(",")}  onChange={this.handleSkuListChange}  />
              </div>
                            <br/>

            <div>
        <label>
          Old Supplier:
          <input type="text" value={this.state.old_supplier} onChange={this.handleOldSupplierChange} />
        </label>
            </div>
                            <br/>

              <div>
        <label>
          New Supplier:
          <input type="text" value={this.state.new_supplier} onChange={this.handleNewSupplierChange} />
        </label>
            </div>
                            <br/>
            <div>
        <label>
          Countries:
          <input type="text" value={this.state.countries.join(",")} onChange={this.handleCountriesChange} />
        </label>
            </div>
                            <br/>

              <div>
        <label>
          Venture:
          <input type="text" value={this.state.venture} onChange={this.handleVentureChange} />
        </label>
              </div>
                            <br/>

            <div>
            <label>
          Should copy stock:
          <input type="checkbox" checked={this.state.should_copy_stock} onChange={this.handleShouldCopyStockChange} />
        </label>
            </div>
                            <br/>

              <div>
            <label>
          Should force migration:
          <input type="checkbox" checked={this.state.should_force_migration} onChange={this.handleShouldForceMigrationChange} />
        </label>
              </div>
              <br/>

        <input type="submit" value="Submit" disabled={this.props.loading} />
      </form>
        </div>
      </span>
    )
  }
}

UploadPage.propTypes = {
  sendSupplierMigrationRequest: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired
}

export default UploadPage
