// Upload
export const UPLOAD_STATUS_INIT = 1
export const UPLOAD_STATUS_SUCCESS = 2
export const UPLOAD_STATUS_FAIL = 3
export const UPLOAD_STATUS_UPLOAD_FAIL = 4

export const UPLOAD_STATUS = {
  [UPLOAD_STATUS_INIT]: 'Init',
  [UPLOAD_STATUS_SUCCESS]: 'Success',
  [UPLOAD_STATUS_FAIL]: 'Fail',
  [UPLOAD_STATUS_UPLOAD_FAIL]: 'Upload fail'
}
