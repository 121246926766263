import {
  GET_OUT_OF_STOCK_STATISTICS,
  GET_OUT_OF_STOCK_STATISTICS_OK,
  GET_OUT_OF_STOCK_STATISTICS_ERROR,
  GET_OUT_OF_STOCK_LIST,
  GET_OUT_OF_STOCK_LIST_OK,
  GET_OUT_OF_STOCK_LIST_ERROR,
} from 'actions/types'

const INITIAL_STATE = {
  loading: false,
  OOSData: {
    warehouse: {
      type: '',
      data: []
    },
    system: {
      type: '',
      data: []
    },
    oos: {
      type: '',
      data: []
    }
  },
  outOfStockList: {
    data: [],
    count: 0,
    page: 1,
  },
}

export default (state = INITIAL_STATE, { type, ...payload }) => {
  switch (type) {
    case GET_OUT_OF_STOCK_STATISTICS:
      return {
        ...state,
        loading: true
      }
    case GET_OUT_OF_STOCK_STATISTICS_OK:
      const OOSData = {
        ...state.OOSData
      }

      if (payload.params.isMissing === undefined) {
        OOSData.oos.data = payload.data
        OOSData.oos.type = payload.params.type
      } else {
        if (payload.params.isMissing) {
          OOSData.warehouse.data = payload.data
          OOSData.warehouse.type = payload.params.type
        } else {
          OOSData.system.data = payload.data
          OOSData.system.type = payload.params.type
        }
      }

      return {
        ...state,
        OOSData,
        loading: false
      }
    case GET_OUT_OF_STOCK_STATISTICS_ERROR:
      return {
        ...state,
        loading: false
      }

    case GET_OUT_OF_STOCK_LIST:
      return {
        ...state,
        loading: true
      }
    case GET_OUT_OF_STOCK_LIST_OK:
      const data = payload.data
      return {
        ...state,
        outOfStockList: {
          data,
          count: parseInt(payload.headers.get('x-pagination-total'), 10),
          page: payload.params.page
        },
        loading: false
      }
    case GET_OUT_OF_STOCK_LIST_ERROR:
      return {
        ...state,
        loading: false
      }
    default:
      return {
        ...state
      }
  }
}
