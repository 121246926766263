import { connect } from 'react-redux'

import SkuPage from 'components/pages/SkuPage'

import * as skuActions from 'actions/sku'

const mapStateToProps = ({
  sku: {
    loading,
    skuData
  }
}) => {
  let skuDataProcessed
  if (skuData) {
    skuDataProcessed = skuData.map((s, i) => ({
      key: i + 1,
      sku: s.Sku,
      country: s.Country,
      warehouse: s.Warehouse,
      supplier_identifier: s.SupplierIdentifier,
      quantity: s.HasQuantity ? s.Quantity : '-',
      reservation: s.HasReservation ? s.Reservation : '-',
      total: s.Total,
      is_manual: s.IsManual,
      children: s.Items && s.Items.length > 0
        ? s.Items.map(item => ({
          key: item,
          reservation: item
        })) : undefined
    }))
  } else {
    skuDataProcessed = []
  }

  const res = {
    loading,
    skuData: skuDataProcessed
  }

  return res
}
const mapDispatchToProps = { ...skuActions }

export default connect(mapStateToProps, mapDispatchToProps)(SkuPage)
