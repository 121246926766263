import { connect } from 'react-redux'
import { ssoLogIn, formLogIn } from 'actions/user'
import LoginPage from 'components/pages/LoginPage'

const mapStateToProps = ({
  user: {
    loading,
    loggedIn,
    token,
    userData
  }
}) => ({
  loading,
  loggedIn,
  token,
  userData
})

const mapDispatchToProps = {
  ssoLogIn,
  formLogIn
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
