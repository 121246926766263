import { REHYDRATE } from 'redux-persist'
import {
  FORM_LOGIN,
  SSO_LOGIN,
  SSO_LOGIN_OK,
  SSO_LOGIN_ERROR,
  LOGOUT
} from 'actions/types'

const INITIAL_STATE = {
  loading: false,
  loggedIn: false,
  token: '',
  userData: {}
}

export default (state = INITIAL_STATE, { type, ...payload }) => {
  switch (type) {
    case FORM_LOGIN:
      return {
        ...state,
        loading: true
      }
    case SSO_LOGIN:
      return {
        ...state,
        loading: true
      }
    case SSO_LOGIN_OK:
      const { Token: token } = payload.data
      // eslint-disable-next-line
      const userData = JSON.parse(atob(token.split('.')[1]))
      return {
        ...state,
        userData,
        token,
        loading: false,
        loggedIn: true
      }
    case SSO_LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        loggedIn: false
      }
    case LOGOUT:
      return INITIAL_STATE
    case REHYDRATE:
      if (payload.payload === undefined) {
        return INITIAL_STATE
      }

      const { user } = payload.payload
      if (user) {
        const { loggedIn, token: userToken, userData: data } = user
        return {
          ...state,
          loggedIn,
          token: userToken,
          userData: data
        }
      }
      break
    default:
      return {
        ...state
      }
  }
}
