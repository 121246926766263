import React from 'react'
import logoWhite from 'static/logo-white.png'
import { NavBar, Icon } from 'antd-mobile'
import PropTypes from 'prop-types'

const HeaderMobile = ({ toggleDrawer }) => (
  <NavBar
    onClick={toggleDrawer}
    style={{ backgroundColor: '#001529' }}
    icon={<Icon type="ellipsis" />}
    onLeftClick={toggleDrawer}
  >
    <div className="logo">
      <img src={logoWhite} alt="logo" />
    </div>
  </NavBar>
)

HeaderMobile.propTypes = {
  toggleDrawer: PropTypes.func.isRequired
}

export default HeaderMobile
