import { GET_ALL_API_KEY, DELETE_API_KEY, SET_API_KEY } from './types'

export const getAllApiKey = () => (
  {
    type: GET_ALL_API_KEY,
    request: {
      url: '/get_key',
      method: 'GET'
    }
  }
)

export const deleteApiKey = apiKey => (
  {
    type: DELETE_API_KEY,
    params: { apiKey },
    request: {
      url: '/del_key',
      method: 'DELETE'
    }
  }
)

export const setApiKey = params => (
  {
    type: SET_API_KEY,
    params,
    request: {
      url: '/set_key',
      method: 'POST'
    }
  }
)
