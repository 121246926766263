import React from 'react'
import { Navigator } from 'routing'
import { Breadcrumb } from 'antd'
import { withRouter } from 'react-router-dom'
import { getRouteByPath } from 'helpers/navigation'
import PropTypes from 'prop-types'

const ContentPage = ({ location }) => {
  const { pathname } = location
  const route = getRouteByPath(pathname)
  const caption = route && route.caption

  return (
    <main>
      <Breadcrumb className="breadcrumb">
        {
          route && <Breadcrumb.Item>Home</Breadcrumb.Item>
        }
        {
          pathname !== '/' ? (
            <Breadcrumb.Item>{caption}</Breadcrumb.Item>
          ) : ''
        }
      </Breadcrumb>
      <Navigator />
    </main>
  )
}

ContentPage.propTypes = {
  location: PropTypes.shape()
}

export default withRouter(ContentPage)
