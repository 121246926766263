import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Redirect } from 'react-router-dom'
import 'style/App.css'
import { isLogin } from 'helpers/sso'
import { LOGIN_PAGE } from 'constants/navigation'
import { getRouteByPath } from 'helpers/navigation'
import { isMobile } from 'helpers/mobile'
import SideBar from './SideBar'
import HeaderMobile from './HeaderMobile'
import HeaderWeb from './HeaderWeb'


class MenuPage extends Component {
  constructor() {
    super()
    this.state = {
      redirect: false,
      menuDefault: '1',
      open: false
    }
  }

  // eslint-disable-next-line
	componentWillMount() {
    const login = isLogin()
    const { logOut, location } = this.props
    if (!login) {
      logOut()
    }

    const { pathname } = location
    const route = getRouteByPath(pathname)
    const state = route && route.state
    this.setState({
      menuDefault: state
    })
  }

  toggleDrawer = () => {
    const { open } = this.state
    this.setState({ open: !open })
  }

  render() {
    const { redirect, menuDefault } = this.state
    if (redirect || !isLogin()) {
      return (
        <Redirect to={
          {
            pathname: LOGIN_PAGE,
            state: { redirect: true }
          }
        }
        />
      )
    }

    const { open } = this.state
    const { userData, logOut } = this.props
    const { picture, name } = userData
    const roleName = userData['role-name']
    return (
      <div>
        {
          isMobile()
            ? (
              <div>
                <HeaderMobile toggleDrawer={this.toggleDrawer} />
                <SideBar
                  logOut={logOut}
                  menuDefault={menuDefault}
                  open={open}
                  toggleDrawer={this.toggleDrawer}
                  roleName={roleName}
                />
              </div>
            )
            : (
              <HeaderWeb
                toggleDrawer={this.toggleDrawer}
                menuDefault={menuDefault}
                picture={picture}
                name={name}
                logOut={logOut}
                roleName={roleName}
              />
            )
        }
      </div>
    )
  }
}

MenuPage.propTypes = {
  logOut: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  userData: PropTypes.shape({
    picture: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    'role-name': PropTypes.string.isRequired
  })
}

export default withRouter(MenuPage)
