import { LOGOUT, SSO_LOGIN, FORM_LOGIN } from './types'

export const ssoLogIn = ({ tokenId }) => ({
  type: SSO_LOGIN,
  params: { tokenId },
  request: {
    url: '/login',
    method: 'POST'
  }
})

export const formLogIn = ( email ) => ({
  type: SSO_LOGIN ,
  params : {"email" : email},
  request: {
    url: '/login/form',
    method: 'POST'
  }
})

export const logOut = () => ({
  type: LOGOUT
})
