import React from 'react'
import PropTypes from 'prop-types'
import { Input, Table, Icon } from 'antd'

const Search = Input.Search

const columns = [
  {
    title: 'Sku',
    dataIndex: 'sku',
    align: 'center'
  },
  {
    title: 'Country',
    dataIndex: 'country',
    align: 'center'
  },
  {
    title: 'Warehouse',
    dataIndex: 'warehouse',
    align: 'center'
  },
  {
    title: 'Supplier Identifier',
    dataIndex: 'supplier_identifier',
    align: 'center'
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    align: 'center'
  },
  {
    title: 'Reservation',
    dataIndex: 'reservation',
    align: 'center'
  },
  {
    title: 'Total',
    dataIndex: 'total',
    align: 'center'
  },
  {
    title: 'Is Manual',
    dataIndex: 'is_manual',
    align: 'center',
    render: isManual => (
      isManual ? <Icon type="check-circle" className="icon-green" /> : null
    ),
  }
]

const SkuPage = ({
  getSkuData,
  loading,
  skuData
}) => {
  const expandIconColumnIndex = columns.findIndex(column => column.dataIndex === 'reservation')
  return (
    <span>
      <div className="content wrapper">
        <Search
          placeholder="input sku"
          onSearch={value => getSkuData(value)}
          enterButton
        />
        <Table
          bordered={true}
          loading={loading}
          pagination={false}
          columns={columns}
          dataSource={skuData}
          size="small"
          expandIconColumnIndex={expandIconColumnIndex}
        />
      </div>
    </span>
  )
}

SkuPage.propTypes = {
  getSkuData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  skuData: PropTypes.arrayOf(PropTypes.shape())
}

export default SkuPage
