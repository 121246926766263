import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Pagination } from 'antd'
import { DEFAULT_PAGE_SIZE } from 'Constants'
import Dragger from './Dragger'
import UploadLogTable from './UploadLogTable'

class UploadPage extends Component {
  componentDidMount() {
    const { uploadLog, getUploadLog } = this.props
    getUploadLog({ page: uploadLog.page, limit: DEFAULT_PAGE_SIZE })
  }

  render() {
    const {
      uploadLog,
      getUploadFile,
      getUploadLog,
      loading
    } = this.props

    return (
      <span>
        <div className="content wrapper">
          <Dragger onUploadComplete={() => { getUploadLog({ page: uploadLog.page, limit: DEFAULT_PAGE_SIZE }) }} />
          <UploadLogTable uploadLog={uploadLog.data} getUploadFile={getUploadFile} loading={loading} />
          <Pagination
            style={{ marginTop: '15px' }}
            defaultCurrent={uploadLog.page}
            total={uploadLog.count}
            showSizeChanger
            onChange={(page, limit) => getUploadLog({ page, limit })}
            onShowSizeChange={(page, limit) => getUploadLog({ page, limit })}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            hideOnSinglePage
          />
        </div>
      </span>
    )
  }
}

UploadPage.propTypes = {
  getUploadFile: PropTypes.func.isRequired,
  getUploadLog: PropTypes.func.isRequired,
  uploadLog: PropTypes.shape(),
  loading: PropTypes.bool.isRequired
}

export default UploadPage
