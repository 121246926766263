import {
  GET_OUT_OF_STOCK_STATISTICS,
  GET_OUT_OF_STOCK_LIST
} from './types'

export const getOutOfStockStatistics = ({ type, n, isMissing }) => (
  {
    type: GET_OUT_OF_STOCK_STATISTICS,
    params: { type, n, isMissing },
    request: {
      url: '/statistics/out_of_stock',
      method: 'GET'
    }
  }
)

export const getOutOfStockList = ({ page, limit }) => (
  {
    type: GET_OUT_OF_STOCK_LIST,
    params: { page, limit },
    request: {
      url: '/out_of_stock',
      method: 'GET'
    }
  }
)
