import { BASE_URL } from 'Constants'

import { message } from 'antd'
import { createToken } from 'helpers/sso'
import { logOut } from 'actions/user'

const fetchMiddleware = store => next => (action) => {
  if (!action || !action.request) {
    return next(action)
  }

  const fetchAction = { ...action }

  const { type } = fetchAction.request
  const fetchRequest = createFetchRequest(fetchAction, createToken(store))

  Promise.race([fetchRequest])
    .then((response) => {
      if (!response.ok || response.status >= 400) {
        if (response.status === 401) {
          response.json().then((content) => {
            message.error(content.Message)
            store.dispatch(logOut())
          })
        } else {
          return Promise.reject(response)
        }
      }

      let contentPromise

      if (type === 'csv') {
        contentPromise = response.text()
      } else {
        contentPromise = response.json()
      }

      contentPromise.then((content) => {
        store.dispatch({
          type: `${action.type}_OK`,
          params: action.params,
          data: content,
          headers: response.headers
        })
      })
    })
    .catch((error) => {
      error.json().then(c => {
        store.dispatch({
          type: `${action.type}_ERROR`,
          params: action.params,
          data: (!!c && c.Message) || error.statusText
        })
      })
    })

  return next(action)
}

const createFetchRequest = (action, token) => {
  const {
    url = '/',
    method = 'GET',
    headers = {},
  } = action.request

  const body = ['POST', 'DELETE'].indexOf(method) >= 0 && action.params
    ? JSON.stringify(action.params)
    : undefined

  const queryParams = ['GET'].indexOf(method) >= 0 && action.params
    ? `?${getEncodedUrlParams(action.params)}`
    : ''

  const TOKEN_KEY = 'Authorization'

  const fetchRequest = fetch(`${getApiUrl(url)}${queryParams}`, {
    method,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      ...(token ? { [TOKEN_KEY]: token } : { }),
      ...headers
    },
    body
  })
  return fetchRequest
}

const getApiUrl = (url) => {
  if (/^http[s]*:\/\/.+/.test(url)) {
    return url
  }
  // eslint-disable-next-line
    return `${BASE_URL}${url}`
}

const getEncodedUrlParams = params => Object.keys(params)
  .map(key => (params[key] !== undefined ? `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}` : ''))
  .join('&')

export default fetchMiddleware
