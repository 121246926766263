import {
  SEND_MIGRATION_REQUEST, SEND_MIGRATION_REQUEST_ERROR, SEND_MIGRATION_REQUEST_OK
} from 'actions/types'

const INITIAL_STATE = {
  loading: false,
  error: false,
  errorMessage: ""
}

export default (state = INITIAL_STATE, {type, data} ) => {
  switch (type) {
    case SEND_MIGRATION_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: ""
      }
    case SEND_MIGRATION_REQUEST_OK:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: ""
      }
    case SEND_MIGRATION_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: data
      }
    default:
      return {
        ...state
      }
  }
}
