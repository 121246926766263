import React from 'react'
import { Line } from 'react-chartjs-2'

import PropTypes from 'prop-types'

const chartColors = ['#FF8F73', '#FFE380', '#79F2C0', '#79E2F2', '#4C9AFF', '#998DD9', '#C1C7D0']

const Chart = ({ labels, data, title }) => {
  const datasets = Object.keys(data).map((key, i) => ({
    label: `OOS ${key.toUpperCase()}`,
    fill: false,
    data: data[key],
    lineTension: 0,
    backgroundColor: chartColors[i],
    borderColor: chartColors[i],
  }))

  const options = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    title: {
      display: true,
      text: title,
      fontSize: 30,
    }
  }

  const initWidth = 1440
  const initHeight = 616
  const initRatio = initWidth / initHeight
  const currentRatio = window.innerWidth / window.innerHeight
  const ratio = (currentRatio * 4) / initRatio

  const chartData = {
    labels,
    datasets
  }
  return (
    <Line width={ratio} height={1} data={chartData} options={options} />
  )
}

Chart.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  data: PropTypes.shape()
}

export default Chart
