import {
  HOME_PAGE,
  UPLOAD_PAGE,
  SKU_PAGE,
  OOS_PAGE, MIGRATION_PAGE
} from 'constants/navigation'
import HomePage from 'containers/Home/HomePage'
import UploadPage from 'containers/Upload/UploadPage'
import SkuPage from 'containers/Sku/SkuPage'
import OOSPage from 'containers/Home/OOSPage'
import MigrationPage from 'containers/SupplierMigration/MigrationPage'

import  {
  GUEST,
  ADMIN
} from 'constants/roles'

const routes = [
  {
    state: HOME_PAGE,
    path: HOME_PAGE,
    component: HomePage,
    caption: 'Dashboard',
    allowedRoles: [
      GUEST,
      ADMIN
    ]
  },
  {
    state: OOS_PAGE,
    path: OOS_PAGE,
    component: OOSPage,
    caption: 'List OOS',
    allowedRoles: [
      GUEST,
      ADMIN
    ]
  },
  {
    state: UPLOAD_PAGE,
    path: UPLOAD_PAGE,
    component: UploadPage,
    caption: 'Upload Stock',
    allowedRoles: [
      ADMIN
    ]
  },
  {
    state: SKU_PAGE,
    path: SKU_PAGE,
    component: SkuPage,
    caption: 'Check Stock',
    allowedRoles: [
      GUEST,
      ADMIN
    ]
  },
  {
    state: MIGRATION_PAGE,
    path: MIGRATION_PAGE,
    component: MigrationPage,
    caption: 'Migration supplier stock',
    allowedRoles: [
      GUEST,
      ADMIN
    ]
  },
]

export default routes
