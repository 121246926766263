import React, { Component } from 'react'
import {
  Layout,
  Form,
  Icon,
  Input,
  Button,
  Divider
} from 'antd'
import PropTypes from 'prop-types'
import GoogleLogin from 'react-google-login'
import { Redirect, withRouter } from 'react-router-dom'
import GoogleButton from 'react-google-button'
import logoImage from 'static/logo.png'
import logoWhite from 'static/logo-white.png'
import { GOOGLE_CLIENT_ID } from 'Constants'
import { isLogin } from 'helpers/sso'
import 'style/App.css'

import { HOME_PAGE } from 'constants/navigation'

const { Header, Content } = Layout

const FormItem = Form.Item

class LoginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false
    }
    this.signUp = this.signUp.bind(this)
    this.loginFormSubmitHandle = this.loginFormSubmitHandle.bind(this);
    this.handleEmailInput = this.handleEmailInput.bind(this);
  }

  signUp(res) {
    const { ssoLogIn } = this.props

    ssoLogIn({
      tokenId: res.tokenId
    })
  }

  handleEmailInput(event) {
    this.setState({email: event.target.value});
  }

  loginFormSubmitHandle(event) {
    const { formLogIn } = this.props
    formLogIn(this.state.email)
    event.preventDefault();
  }

  render() {
    const { redirect } = this.state
    if (redirect || isLogin()) {
      return (
        <Redirect to={
          {
            pathname: HOME_PAGE,
            state: { redirect: true }
          }
        }
        />
      )
    }

    // eslint-disable-next-line
    const { form } = this.props
    const { getFieldDecorator } = form
    const responseGoogle = (response) => {
      this.signUp(response)
    }

    return (
      <span>
        <Header>
          <div className="logo">
            <img src={logoWhite} alt="Logo" />
          </div>
        </Header>
        <Content className="login-wrapper">
          <div className="wrapper">
            <div className="login-content">
              <img src={logoImage} alt="Logo" />
              <Divider />
              <Form className="login-form" onSubmit={this.loginFormSubmitHandle}>
                <FormItem>
                  {getFieldDecorator('email', {
                    rules: [{ required: true, message: 'Please input your email!' }]
                  })(
                    <Input
                      prefix={<Icon type="user" className="icon" />}
                      placeholder="Email" onChange={this.handleEmailInput}
                    />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please input your Password!' }]
                  })(
                    <Input
                      prefix={<Icon type="lock" className="icon" />}
                      type="password"
                      placeholder="Password"
                    />
                  )}
                </FormItem>
                <FormItem>
                  <Button type="submit" htmlType="submit" className="login-form-button">
                  Log in
                  </Button>
                </FormItem>
              </Form>
              <Divider />

              <div>
                <GoogleLogin
                  className="google-button"
                  clientId={GOOGLE_CLIENT_ID}
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                >
                  <GoogleButton />
                </GoogleLogin>
              </div>
            </div>
          </div>
        </Content>
      </span>
    )
  }
}

LoginPage.propTypes = {
  ssoLogIn: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired
  })
}

export default withRouter(Form.create()(LoginPage))
