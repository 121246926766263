import { connect } from 'react-redux'

import { logOut } from 'actions/user'

import MenuPage from 'components/pages/Menu/MenuPage'

const mapStateToProps = ({
  user: {
    loading,
    loggedIn,
    token,
    userData
  }
}) => ({
  loading,
  loggedIn,
  token,
  userData
})

const mapDispatchToProps = {
  logOut
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuPage)
