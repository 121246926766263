import React from 'react'
import logoWhite from 'static/logo-white.png'
import PropTypes from 'prop-types'
import { Layout } from 'antd'
import HeaderNavigator from 'components/pages/Navigator/HeaderNavigator'

const { Header } = Layout


const HeaderWeb = ({
  toggleDrawer,
  menuDefault,
  name,
  picture,
  logOut,
  roleName
}) => (
  <Header>
    <div className="logo">
      <img src={logoWhite} alt="logo" />
    </div>
    <HeaderNavigator
      toggleDrawer={toggleDrawer}
      menuDefault={menuDefault}
      name={name}
      picture={picture}
      logOut={logOut}
      roleName={roleName}
    />
  </Header>
)

HeaderWeb.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  menuDefault: PropTypes.string,
  name: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
  logOut: PropTypes.func.isRequired,
  roleName: PropTypes.string.isRequired
}

export default HeaderWeb
