import { connect } from 'react-redux'

import { getOutOfStockStatistics } from 'actions/stock'

import HomePage from 'components/pages/Home/HomePage'

const mapStateToProps = ({
  stock: {
    loading,
    OOSData
  }
}) => {
  // eslint-disable-next-line
  const formattedOOSData = {}
  Object.keys(OOSData).forEach((key) => {
    formattedOOSData[key] = formatOOSData(OOSData[key].data)
  })

  const res = {
    loading,
    OOSData,
    formattedOOSData
  }
  return res
}

const formatOOSData = OOSData => OOSData.reduce((obj, item) => {
  if (obj && obj[item.Country]) {
    return {
      ...obj,
      [item.Country]: {
        ...obj[item.Country],
        [item.OutOfStockAt]: item.Quantity
      }
    }
  }
  return {
    ...obj,
    [item.Country]: { [item.OutOfStockAt]: item.Quantity }
  }
}, {})

const mapDispatchToProps = {
  getOutOfStockStatistics
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
