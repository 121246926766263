import { connect } from 'react-redux'

import { getUploadLog, getUploadFile } from 'actions/upload'
import UploadPage from 'components/pages/Upload/UploadPage'
import { logOut } from 'actions/user'
import { UPLOAD_STATUS } from 'constants/status'

const mapStateToProps = ({
  user: {
    loggedIn,
    token,
    userData
  },
  upload: {
    loading,
    uploadLog
  }
}) => {
  const uploadLogMapped = uploadLog.data.map(log => ({
    key: log.IdUploadLog,
    IdUploadLog: log.IdUploadLog,
    FileName: log.FileName,
    Email: log.User.Email,
    UpdatedAt: (new Date(log.UpdatedAt)).toLocaleString(),
    FkUploadStatus: UPLOAD_STATUS[log.FkUploadStatus],
    Errors: log.Errors
  }))

  return {
    loading,
    loggedIn,
    token,
    userData,
    uploadLog: {
      data: uploadLogMapped,
      count: uploadLog.count,
      page: uploadLog.page
    }
  }
}

const mapDispatchToProps = {
  logOut,
  getUploadLog,
  getUploadFile
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadPage)
