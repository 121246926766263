import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import {
  convertDateToDayAndMonthAndYear,
  convertDateToMonthAndYear,
  convertDateToWeekAndYear
} from 'helpers/date'
import Chart from './Chart'

class Home extends Component {
  componentDidMount() {
    const { getOutOfStockStatistics } = this.props
    getOutOfStockStatistics({
      type: 'day',
      n: 14
    })

    getOutOfStockStatistics({
      type: 'day',
      n: 14,
      isMissing: true
    })

    getOutOfStockStatistics({
      type: 'day',
      n: 14,
      isMissing: false
    })
  }

  getChartLabels(type) {
    switch (type) {
      case 'day':
        return this.generateLastNDayLabels(14)
      case 'week':
        return this.generateLastNWeekLabels(12)
      case 'month':
        return this.generateLastNMonthLabels(12)
      default:
        return this.generateLastNDayLabels(14)
    }
  }

  getOOSDataByCountriesAndLabels(OOSData, countries, labels) {
    const data = {}

    countries.forEach((country) => {
      const countryData = OOSData[country]
      labels.forEach((label) => {
        const quantity = countryData && countryData[label] ? countryData[label] : 0
        if (data[country]) {
          data[country].push(quantity)
        } else {
          data[country] = [quantity]
        }
      })
    })

    return data
  }

  generateLastNDayLabels(N) {
    return [...Array(N)].map((_, i) => {
      const date = new Date()
      date.setDate(date.getDate() - i)
      return convertDateToDayAndMonthAndYear(date)
    }).reverse()
  }

  generateLastNWeekLabels(N) {
    return [...Array(N)].map((_, i) => {
      const date = new Date()
      date.setDate(date.getDate() - i * 7)
      return convertDateToWeekAndYear(date)
    }).reverse()
  }

  generateLastNMonthLabels(N) {
    return [...Array(N)].map((_, i) => {
      const date = new Date()
      date.setMonth(date.getMonth() - i)
      return convertDateToMonthAndYear(date)
    }).reverse()
  }

  renderChart(OOSData, type, title, isMissing) {
    if (OOSData) {
      const labels = this.getChartLabels(type)
      const countries = ['hk', 'id', 'my', 'ph', 'sg', 'tw']
      const data = this.getOOSDataByCountriesAndLabels(OOSData, countries, labels)

      const { getOutOfStockStatistics } = this.props

      return (
        <div className="content wrapper">
          <Button
            onClick={() => { getOutOfStockStatistics({ type: 'day', n: 14, isMissing }) }}
            className="chart-btn"
          >
            Day
          </Button>
          <Button
            onClick={() => { getOutOfStockStatistics({ type: 'week', n: 12, isMissing }) }}
            className="chart-btn"
          >
            Week
          </Button>
          <Button
            onClick={() => { getOutOfStockStatistics({ type: 'month', n: 12, isMissing }) }}
            className="chart-btn"
          >
            Month
          </Button>
          <Chart data={data} labels={labels} title={title} />
        </div>
      )
    }
  }

  render() {
    const { OOSData, formattedOOSData } = this.props
    return (
      <span>
        {
          this.renderChart(formattedOOSData.oos, OOSData.oos.type, 'General OOS')
        }
        {
          this.renderChart(
            formattedOOSData.warehouse,
            OOSData.warehouse.type,
            'Warehouse OOS',
            true
          )
        }
        {
          this.renderChart(
            formattedOOSData.system,
            OOSData.system.type,
            'System OOS',
            false
          )
        }
      </span>
    )
  }
}

Home.propTypes = {
  getOutOfStockStatistics: PropTypes.func.isRequired,
  formattedOOSData: PropTypes.shape(),
  OOSData: PropTypes.shape()
}

export default Home
