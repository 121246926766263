import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

export const withAuthorization = (WrappedComponent, allowedRoles) => {
  const Authorization = ({ userData }) => {
    const roleName = userData['role-name']
    return allowedRoles.includes(roleName) ? <WrappedComponent /> : <Redirect to="/error" />
  }

  Authorization.propTypes = {
    userData: PropTypes.shape()
  }

  const mapStateToProps = ({ user: { userData } }) => ({
    userData
  })

  return connect(mapStateToProps)(Authorization)
}
