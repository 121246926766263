import {
  GET_ALL_API_KEY,
  GET_ALL_API_KEY_OK,
  DELETE_API_KEY,
  DELETE_API_KEY_OK,
  SET_API_KEY,
  SET_API_KEY_OK
} from 'actions/types'

const INITIAL_STATE = {
  loading: false,
  apiKeys: undefined,
  newApiKey: undefined
}

export default (state = INITIAL_STATE, { type, ...payload }) => {
  switch (type) {
    case GET_ALL_API_KEY:
      return {
        ...state,
        loading: true
      }
    case GET_ALL_API_KEY_OK:
      const apiKeys = payload.data
      return {
        ...state,
        apiKeys,
        loading: false
      }
    case DELETE_API_KEY:
      return {
        ...state,
        loading: true
      }
    case DELETE_API_KEY_OK:
      return {
        ...state,
        apiKeys: payload.data,
        loading: false
      }
    case SET_API_KEY:
      return {
        ...state,
        loading: true
      }
    case SET_API_KEY_OK:
      return {
        ...state,
        loading: false,
        newApiKey: payload.data.apiKey
      }
    default:
      return {
        ...state
      }
  }
}
