export const getWeek = (d) => {
  const date = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7))
  const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1))
  const weekNo = Math.ceil((((date - yearStart) / 86400000) + 1) / 7)
  return weekNo
}

export const convertDateToDayAndMonthAndYear = (date) => {
  const day = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`
  const realMonth = date.getMonth() + 1
  const month = realMonth >= 10 ? realMonth : `0${realMonth}`
  const year = date.getFullYear()
  return `${day}-${month}-${year}`
}

export const convertDateToWeekAndYear = (date) => {
  const week = getWeek(date)
  const year = date.getFullYear()
  const weekAndYear = week >= 10 ? `${week}-${year}` : `0${week}-${year}`
  return weekAndYear
}

export const convertDateToMonthAndYear = (date) => {
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const monthAndYear = month >= 10 ? `${month}-${year}` : `0${month}-${year}`
  return monthAndYear
}
