import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ApiKeyPage from 'containers/ApiKey/ApiKeyPage'
import SetKeyForm from 'containers/ApiKey/SetKeyForm'
import ErrorPage from 'components/pages/ErrorPage'
import { withAuthorization } from 'containers/Layout/Authorization'

import routes from './routes'

export const Navigator = () => {
  const Routes = routes.map(
    route => (
      <Route
        key={route.state}
        exact
        path={route.path}
        component={withAuthorization(route.component, route.allowedRoles)}
      />
    )
  )
  return (
    <Switch>
      {Routes}
      <Route path="/keys" component={ApiKeyPage} />
      <Route path="/set-key" component={SetKeyForm} />
      <Route path="*" component={ErrorPage} />
    </Switch>
  )
}
