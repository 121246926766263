import React, { Component } from 'react'
import { Pagination } from 'antd'
import PropTypes from 'prop-types'
import { DEFAULT_PAGE_SIZE } from 'Constants'
import OOSLogTable from './OOSLogTable'

class OOSPage extends Component {
  componentDidMount() {
    const {
      getOutOfStockList,
      outOfStockList,
    } = this.props
    getOutOfStockList({ page: outOfStockList.page, limit: DEFAULT_PAGE_SIZE })
  }

  render() {
    const {
      outOfStockList,
      getOutOfStockList,
      loading
    } = this.props

    return (
      <span>
        <div className="content wrapper">
          <Pagination
            defaultCurrent={outOfStockList.page}
            total={outOfStockList.count}
            showSizeChanger
            onChange={(page, limit) => getOutOfStockList({ page, limit })}
            onShowSizeChange={(page, limit) => getOutOfStockList({ page, limit })}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            hideOnSinglePage
          />
          <OOSLogTable outOfStockList={outOfStockList.data} loading={loading} />
        </div>
      </span>
    )
  }
}

OOSPage.propTypes = {
  getOutOfStockList: PropTypes.func.isRequired,
  outOfStockList: PropTypes.shape(),
  loading: PropTypes.bool.isRequired
}

export default OOSPage
