import { GET_SKU_DATA } from './types'

export const getSkuData = sku => (
  {
    type: GET_SKU_DATA,
    params: { sku },
    request: {
      url: '/check-stock',
      method: 'GET'
    }
  }
)
