import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Button, Modal } from 'antd'
import { Link } from 'react-router-dom'

const { confirm } = Modal

class ApiKeyPage extends Component {
  componentDidMount = () => {
    const { getAllApiKey } = this.props
    getAllApiKey()
  }

  showConfirm(apiKey) {
    const { deleteApiKey } = this.props
    confirm({
      title: 'Do you want to delete these items?',
      content: 'When clicked the OK button, the api key will be deleted permanently',
      onOk() {
        deleteApiKey(apiKey)
      },
      onCancel() {},
    })
  }

  render() {
    const { loading, apiKeys } = this.props
    const columns = [
      {
        title: 'Service',
        dataIndex: 'apiKey',
        align: 'center',
        width: '10%',
        key: 'service',
        render: row => (
          <span>
            {/* eslint-disable-next-line no-undef */}
            {JSON.parse(atob(row.split('.')[1])).service}
          </span>
        )
      },
      {
        title: 'Api Key',
        dataIndex: 'apiKey',
        align: 'center',
        width: '70%'
      },
      {
        title: 'Role',
        dataIndex: 'role',
        align: 'center',
        width: '10%'
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        width: '10%',
        render: row => (
          <span>
            <Button onClick={() => { this.showConfirm(row.apiKey) }}>Delete</Button>
          </span>
        )
      }
    ]

    return (
      <span>
        <div className="content wrapper">
          <Table
            bordered={true}
            loading={loading}
            pagination={false}
            columns={columns}
            dataSource={apiKeys}
            rowKey="apiKey"
            size="small"
          />
          <Link style={{ marginTop: '15px' }} className="ant-btn ant-btn-primary" to="/set-key">New Key</Link>
        </div>
      </span>
    )
  }
}

ApiKeyPage.propTypes = {
  apiKeys: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool.isRequired,
  deleteApiKey: PropTypes.func.isRequired,
  getAllApiKey: PropTypes.func.isRequired
}

export default ApiKeyPage
